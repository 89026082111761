<template>
  <div>
    <Main>
      <ErrorWrapper>
        <img :src="require(`@/static/img/pages/404.svg`)" alt="404" />
        <sdHeading class="error-text" as="h3"> 404 </sdHeading>

        <p>{{ i18n.t('messages.pageNotFound') }}</p>
        <router-link to="/">
          <sdButton size="default" type="primary" to="/"> {{ i18n.t('messages.returnHome') }} </sdButton>
        </router-link>
      </ErrorWrapper>
    </Main>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { Main } from '../styled';
import { ErrorWrapper } from './style';
export default {
  name: '404',
  components: {
    Main,
    ErrorWrapper,
  },
  setup() {
    const i18n = useI18n();

    return { i18n };
  },
};
</script>
